import React from 'react'

import { WorksHero } from 'blocks/Works/WorksHero'
import { WorksProjectGrid } from 'blocks/Works/WorksProjectGrid'
import { HelmetFunc } from 'components/PageMetaData'
import { Layout } from 'layout/Layout'
import { DefaultFormBlock } from 'blocks/DefaultFormBlock'
import { Reviews } from 'blocks/Reviews'

const pageMetadata = {
    uk: {
        title: 'Портфоліо дизайну інтер`єрів студії',
        description:
            'У цьому розділі представлені фотографії завершених об`єктів та короткий опис історії створення дизайн-проектів до кожного з них',
    },
    ru: {
        title: 'Портфолио дизайна интерьеров студии',
        description:
            'В этом разделе представленны фотографии завершенных объектов и краткое описание истории создания дизайн-проектов к каждому из них',
    },
    en: {
        title: 'Interior Design Portfolio',
        description:
            'This section contains photographs of completed objects and a brief description of the history of the creation of design projects for each of them',
    },
}

const WorksPage = () => {
    return (
        <Layout>
            <HelmetFunc data={pageMetadata} />
            <WorksHero />
            <WorksProjectGrid />
            <Reviews />
            <DefaultFormBlock
                withPhoneMobile
                tracking={{
                    conversionType: 'FormWorksPageBottom',
                    eventCategory: 'Bottomform',
                }}
            />
        </Layout>
    )
}

export default WorksPage
